import { sendNotifications } from '../../../helpers/push-notification/sendNotifications';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { reset } from 'redux-form';
import { toastr }  from 'react-redux-toastr';

async function submit(values, dispatch) {
    let content = {
	    title: values.title,
        message: values.message
    };
    
    let userType = values.to;
    
    dispatch(setLoaderStart('sendNotification'));
    
    const {status, errorMessage} = sendNotifications('notification', content, null, userType);
    console.log("*** ADMIN SEND NOTIFICATION:", status, errorMessage);
    if(status == 200){
        toastr.success('Success!', 'The notification has been sent successfully.');
    } else {
        console.error("Send Notification Error:", errorMessage);
        toastr.error('Failed', 'Unable to send notification');
    }
    dispatch(reset('ManageNotificationsForm'));
    dispatch(setLoaderComplete('sendNotification'));
}

export default submit;
