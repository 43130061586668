import fetch from 'node-fetch';
import { toastr } from 'react-redux-toastr';
import { pushNotificationMessage } from './pushNotificationMessage';
import { siteUrl } from '../../config';

 
export async function sendNotifications(type, requestContent, userId, userType, lang) {
    let content = requestContent;
    
    if (type !== 'notification') {
        const { title, message } = await pushNotificationMessage(type, requestContent, lang);
        content = {title, message, ...content}
    }
    
    // 'http://localhost:3000/push-notification
    const resp = await fetch('http://localhost:3000/push-notification', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            content,
            userId,
            userType
        }),
        credentials: 'include'
    }).catch(err => {
        console.log("SEND PUSH NOTIFICATION:", err);
    });
    
    const { status, errorMessge } = await resp.json();
   
    return {
        status,
        errorMessge
    };
}

export default sendNotifications;
