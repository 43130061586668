exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VerifyPhoneNumberForm-verifyBtn-3NjWI {\n    display: inline-block;\n    margin-right: 12px;\n    position: relative;\n    top: 9px;\n}\n.VerifyPhoneNumberForm-textVrify-1mavW {\n    text-align: right;\n}\n.VerifyPhoneNumberForm-labelColor-KojO6 {\n    font-size: 16px;\n    color: #484848;\n}\n.VerifyPhoneNumberForm-displayInine-36O0r{\n    display: inline-block;\n    position: relative;\n    top: 9px;\n}\n@media screen and (max-width: 1200px) {\n    .VerifyPhoneNumberForm-textVrify-1mavW {\n        text-align: center;\n    }\n    .VerifyPhoneNumberForm-verifyBtn-3NjWI {\n        display: block;\n        margin-right: 0px;\n        margin-bottom: 24px;\n    }\n    .VerifyPhoneNumberForm-btnPadding-267vT {\n        padding: 12px 15px !important;\n    }\n}", "", {"version":3,"sources":["/root/ASAP-Food-Web/src/components/Shop/EditProfileForm/VerifyPhoneNumberForm/VerifyPhoneNumberForm.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;CACZ;AACD;IACI,kBAAkB;CACrB;AACD;IACI,gBAAgB;IAChB,eAAe;CAClB;AACD;IACI,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;CACZ;AACD;IACI;QACI,mBAAmB;KACtB;IACD;QACI,eAAe;QACf,kBAAkB;QAClB,oBAAoB;KACvB;IACD;QACI,8BAA8B;KACjC;CACJ","file":"VerifyPhoneNumberForm.css","sourcesContent":[".verifyBtn {\n    display: inline-block;\n    margin-right: 12px;\n    position: relative;\n    top: 9px;\n}\n.textVrify {\n    text-align: right;\n}\n.labelColor {\n    font-size: 16px;\n    color: #484848;\n}\n.displayInine{\n    display: inline-block;\n    position: relative;\n    top: 9px;\n}\n@media screen and (max-width: 1200px) {\n    .textVrify {\n        text-align: center;\n    }\n    .verifyBtn {\n        display: block;\n        margin-right: 0px;\n        margin-bottom: 24px;\n    }\n    .btnPadding {\n        padding: 12px 15px !important;\n    }\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"verifyBtn": "VerifyPhoneNumberForm-verifyBtn-3NjWI",
	"textVrify": "VerifyPhoneNumberForm-textVrify-1mavW",
	"labelColor": "VerifyPhoneNumberForm-labelColor-KojO6",
	"displayInine": "VerifyPhoneNumberForm-displayInine-36O0r",
	"btnPadding": "VerifyPhoneNumberForm-btnPadding-267vT"
};